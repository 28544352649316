<template>
  <div>
    <v-container>
      <contactRegistration></contactRegistration>
    </v-container>
  </div>
</template>
<script>
import appConfig from '@src/app.config'
import contactRegistration from '@components/contactRegistration.vue'

export default {
  page: {
    title: 'Contact Registration',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { contactRegistration },
}
</script>
