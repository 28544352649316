<template>
  <div>
    <v-row v-if="applicationsAccepted">
      <v-col cols="12">
        <h1 class="text-h4 primary--text">Customer Contact Registration</h1>
        <h2 class="text-subtitle-1 primary--text"
          >Please enter the following information
          <BaseHelpTooltip
            iconColor="primary"
            tooltipText="Review Instructions"
            header="Filing Instructions"
            :maxWidth="850"
            ><div><FilingInstructions></FilingInstructions> </div>
          </BaseHelpTooltip>
        </h2>
      </v-col>
      <v-col cols="12">
        <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
          <v-card color="formSectionBg" flat class="pa-2">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="First Name"
                    v-model="selectedContact.FirstName"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="M.I."
                    v-model="selectedContact.MiddleInitial"
                    return-masked-value
                    v-mask="'A'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Last Name"
                    v-model="selectedContact.LastName"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="SSN"
                    v-model="selectedContact.Ssn"
                    return-masked-value
                    v-mask="'###-##-####'"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Phone"
                    v-model="selectedContact.Phone"
                    return-masked-value
                    v-mask="'(###) ###-####'"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Mailing Address1"
                    v-model="selectedContact.MailingAddress1"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Mailing Address2"
                    v-model="selectedContact.MailingAddress2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="City"
                    v-model="selectedContact.City"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <LuState
                    label="State"
                    v-model="selectedContact.StateId"
                    :rules="[(v) => !!v || 'required']"
                  ></LuState>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Zip"
                    v-model="selectedContact.Zip"
                    :rules="[(v) => !!v || 'required']"
                    v-mask="['#####', '#####-####']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
        <div class="d-inline-flex float-left">
          <v-btn
            @click="saveContactDetails"
            :disabled="saving"
            color="primary"
            class="mr-4 mt-4"
            >Next</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card v-if="currentLuPeriod || errorMessage" class="mx-auto my-5 pa-8">
        <div v-if="currentLuPeriod">
          <div v-if="beforePeriod">
            The application period for {{ currentLuPeriod.Period }} refunds will
            open
            {{
              dayjs(currentLuPeriod.ApplicationOpenDate).format('MM/DD/YYYY')
            }}</div
          >
          <div v-if="applicationDeadlineExpired">
            <p>
              Application submissions for {{ currentLuPeriod.Period }} refunds
              are no longer being accepted.
            </p>
          </div>
          <div v-if="afterPeriod">
            The application period for {{ currentLuPeriod.Period }} refunds has
            closed
          </div>
        </div>
        <div v-else-if="errorMessage">{{ errorMessage }}</div>
      </v-card></v-row
    >
  </div>
</template>

<script>
import FilingInstructions from '@components/instructions.vue'
import dayjs from 'dayjs'
import { authComputed } from '@state/helpers'
import LuState from '@components/select/LuState/LuState-single'
import UserRole from '@classes/UserRole'
import { mask } from 'vue-the-mask'
import Contact from '@classes/Contact'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  name: 'ContactRegistration',
  props: {},

  data() {
    return {
      selectedContact: new Contact(),
      valid: true,
      dayjs,
    }
  },
  directives: {
    mask,
  },
  components: {
    LuState,
    FilingInstructions,
  },
  computed: {
    ...authComputed,
    ...get('luPeriod', [
      'currentLuPeriod',
      'errorMessage',
      'withinPeriod',
      'beforePeriod',
      'applicationDeadlineExpired',
      'applicationsAccepted',
      'afterPeriod',
    ]),
    ...get('contact', ['saving']),
  },
  created() {
    this.populateFirstLastName()
  },
  methods: {
    ...call('contact', ['saveContact', 'refreshSelectedContact']),
    ...call('userRole', ['createUserRole']),
    ...call('auth', ['retrieveUserDetail']),
    async validate() {
      await this.$refs.form.validate()
    },
    async saveContactDetails() {
      var valid = await this.validate()

      if (this.valid) {
        try {
          var varContact
          var contact = this.selectedContact.removeRelated()

          if (this.currentUser.UserRoles.length == 0) {
            contact.UserId = this.currentUser.Id
            var newUser = new UserRole({
              UserId: this.currentUser.Id,
              RoleId: 3,
            })
            varContact = await this.saveContact(contact)
            await this.createUserRole(newUser.removeRelated())
            await this.retrieveUserDetail(this.currentUser.Email)
          } else if (this.isAdmin) {
            await this.saveContact(contact)
          }

          this.$router.push({
            name: 'consumerForm',
            query: { contactId: varContact.data.Id },
          })
          //todo: here we want to decide what we do after the save is complete, go to another page?
        } catch (err) {
          console.error(err)
        }
        this.dialog = false
      }
    },
    populateFirstLastName() {
      if (
        this.currentUser &&
        this.currentUser.Id > 0 &&
        (this.isConsumer || this.currentUser.UserRoles.length == 0)
      ) {
        this.selectedContact.FirstName = this.currentUser.FirstName
        this.selectedContact.LastName = this.currentUser.LastName
      }
    },
  },
  watch: {
    currentUser(nval, oval) {
      if (nval) {
        this.populateFirstLastName()
      }
    },
  },
}
</script>

<style scoped>
.btn {
  appearance: none;
  background: transparent;
  border: 2px solid rgb(8, 0, 0);
  color: rgb(38, 148, 57);
  font: 11px / 1 arial;
  letter-spacing: 0.3px;
  padding: 4px 12px;
  height: 24px;
  border-radius: 12px;
}
</style>
